import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect = () => {
    if (this.hasFormTarget) {
      setTimeout(() => { this.formTarget.submit(); }, 100);
    }
  };
}
