import $ from 'jquery';
import { i18n } from '../custom/Internationalization';
import Toastr from './Toastr';
import { getCSRFTokenValue } from '../custom/CsrfToken';

export const getFilestackUrlForFile = (file, filestack = null) => {
  const client = filestack || window.fpGroupize;

  return `${file.url}?signature=${client.session.signature}&policy=${client.session.policy}`;
};

export const filestackAllowedImageTypes = [
  '.png',
  '.jpg',
  '.jpeg',
  '.bmp',
  '.gif',
  '.tiff',
  '.tif',
  '.webp',
];
export const filestackAllowedVideoTypes = [
  '.mp4',
  '.m4v',
  '.mov',
  '.avi',
  '.wmv',
  '.mpg',
  '.mpeg',
];
export const defaultFilestackOptions = () => {
  const defaults = {
    transformationsUI: false,
    fromSources: ['local_file_system', 'imagesearch'],
    accept: [
      '.pdf',
      '.doc',
      '.docx',
      '.ppt',
      '.pptx',
      '.xls',
      '.xlsx',
      '.csv',
      '.mp4',
      '.m4v',
      '.mov',
      '.avi',
      '.wmv',
      '.mpg',
      '.mpeg',
      'audio/*',
      '.txt',
      '.rtf',
      '.zip',
      '.png',
      '.jpg',
      '.jpeg',
      '.bmp',
      '.gif',
      '.tiff',
      '.tif',
      '.webp',
    ].concat(filestackAllowedImageTypes),
    // eslint-disable-next-line consistent-return
    acceptFn: (file, options) => {
      if (file?.originalFile) {
        options.mimeFromMagicBytes(file.originalFile).then((res) => {
          const mimeType = res || options.mimeFromExtension(file.originalFile.name);
          if (options.acceptMime.indexOf(mimeType) === -1) {
            Toastr.error(i18n.t('file.upload_error'));
            return Promise.reject(new Error('Cannot accept that file'));
          }
          return Promise.resolve();
        });
      } else if (file?.mimetype) {
        if (options.acceptMime.indexOf(file.mimetype) === -1) {
          Toastr.error(i18n.t('file.upload_error'));
          return Promise.reject(new Error('Cannot accept that file'));
        }
        return Promise.resolve();
      } else {
        return Promise.resolve();
      }
    },
  };
  return defaults;
};

export const previewFilestackFile = (link, event, type = '') => {
  if (event != null) {
    event.preventDefault();
  }

  if ($('#modal_preview').length) {
    $('#modal_preview').remove();
  }

  const modal = [
    "<div class='ui modal' id='modal_preview'>",
    "  <i class='close icon'></i>",
    "  <h3 class='header'>Preview</h3>",
    "  <div class='frame-wrapper' id='filestack_modal_preview_content' style='height: 800px;'></div>",
    '</div>',
  ].join('');
  $('body').append(modal);

  $('#modal_preview').modal('show');

  const url = $(link).attr('href');
  if (type === 'video/mp4') {
    const video = [
      '<video width="100%" controls>"',
      ` <source src="${url}" id="video_here">`,
      ' Your browser does not support HTML5 video.',
      '</video>',
    ].join('');
    $('#filestack_modal_preview_content').append(video);
  } else {
    window.fpGroupize.preview(url, { id: 'filestack_modal_preview_content' });
  }
};
window.previewFilestackFile = previewFilestackFile;

window.updateFilestackVideoPreview = (data, previewOptions) => {
  if (data.filesUploaded.length > 0) {
    const previewId = previewOptions.previewContainer;
    const file = data.filesUploaded[0];
    const url = getFilestackUrlForFile(file);

    const previewContainer = document.getElementById(previewId);
    const videoLink = $(previewContainer).find('.video-link');
    videoLink.attr('href', url);
    $(previewContainer).removeClass('hidden').addClass('show');
  }
};

window.updateFilestackFileDownloadLinks = (data, previewOptions) => {
  const previewContainer = $(previewOptions.elem).parents(previewOptions.previewContainer);

  if (data.filesUploaded.length > 0) {
    const file = data.filesUploaded[0];
    const url = getFilestackUrlForFile(file);

    const downloadLink = previewContainer.find('.filestack_download');
    downloadLink.attr('href', url).removeClass('hide');

    const previewLink = previewContainer.find('.filestack_preview');
    previewLink.attr('href', url).removeClass('hide');

    const removeLink = previewContainer.find('.filestack_remove');
    removeLink.attr('href', url).removeClass('hide');

    const uploadLink = previewContainer.find('.filestack_upload');
    uploadLink.addClass('hide');
  }
};

window.updateFilestackNestedImagePreview = (data, previewOptions) => {
  if (data.filesUploaded.length > 0) {
    const file = data.filesUploaded[0];
    const url = getFilestackUrlForFile(file);
    const parentContainer = $(previewOptions.elem).parents('.filestack_container');
    const previewView = parentContainer.find('.filestack_preview_view');
    $(previewView).attr('src', url).removeClass('hide');
    parentContainer.find('.filestack_remove').removeClass('hide');
    parentContainer.find('.filestack_change').removeClass('hide');
    parentContainer.find('.filestack_add').addClass('hide');
  }
};

window.replaceProfileImage = (data, previewOptions) => {
  if (data.filesUploaded.length > 0) {
    $('.gr-initials').addClass('hide');
    window.updateFilestackNestedImagePreview(data, previewOptions);
  }
};

window.removeFilestackFile = (containerSelector, inputSelector) => {
  const downloadLink = $(containerSelector).find('.filestack_download');
  downloadLink.attr('href', '').addClass('hide');

  const previewLink = $(containerSelector).find('.filestack_preview');
  previewLink.attr('href', '').addClass('hide');

  const uploadLink = $(containerSelector).find('.filestack_upload');
  uploadLink.removeClass('hide');

  const previewView = $(containerSelector).find('.filestack_preview_view');
  let blankSrc = '';
  if (typeof (previewView.data('default-img')) !== 'undefined') {
    blankSrc = previewView.data('default-img');
  }
  previewView.attr('src', blankSrc);

  const removeLink = $(containerSelector).find('.filestack_remove');
  removeLink.addClass('hide');

  const addLink = $(containerSelector).find('.filestack_add');
  addLink.removeClass('hide');

  const changeLink = $(containerSelector).find('.filestack_change');
  changeLink.addClass('hide');

  const input = $(containerSelector).find(inputSelector);
  input.val('');
};

const removeAttachmentFilename = (event, inputSelector) => {
  event.preventDefault();

  const parentSection = $(event.target).parents('.field');

  let input = parentSection.find('input[type=filepicker]');
  if (typeof (inputSelector) !== 'undefined') {
    input = $(inputSelector);
  }

  const $previewLink = parentSection.find('.preview.button');
  const $removeLink = parentSection.find('.remove.button');
  const $attachLink = parentSection.find('.attach_file.button');
  const $additionalFields = parentSection.find('.filestack_additional');

  $previewLink.addClass('hide');
  $removeLink.addClass('hide');
  $attachLink.removeClass('hide');

  input.val(null);
  $additionalFields.val(null);
};
window.removeAttachmentFilename = removeAttachmentFilename;

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-fs-remove-attachment]').forEach((el) => {
    el.addEventListener('click', (clickEvent) => {
      removeAttachmentFilename(clickEvent, el.dataset.fsRemoveAttachment);
    });
  });
});

window.updateAttachmentFilename = (data, previewOptions) => {
  const selector = previewOptions.previewContainer;
  const file = data.filesUploaded[0];
  const elem = $(selector);
  const url = getFilestackUrlForFile(file);

  const $fieldClass = elem.parents('.field');
  const $previewLink = $fieldClass.find('.preview.button');
  const $removeLink = $fieldClass.find('.remove.button');
  const $attachLink = $fieldClass.find('.attach_file.button');

  if ($previewLink.length > 0) {
    $previewLink.attr('href', url);
    $previewLink.attr('data-mime-type', file.mimetype);
    $previewLink.attr('data-file_size', file.size);
    $previewLink.find('span').text(file.filename);
    $fieldClass.find('.file_mime_type').attr('value', file.mimetype);
    $fieldClass.find('.file_size').attr('value', file.size);
    $fieldClass.find('.file_name').attr('value', file.filename);
    $fieldClass.find('.preview_url').attr('value', url);
    $fieldClass.find('.attachment_url').attr('value', url);
    $previewLink.removeClass('hide');
    $removeLink.removeClass('hide');
    $attachLink.addClass('hide');
  }
};

window.uploadFile = (data, options) => {
  const {
    url,
    filename,
    mimetype,
    size,
  } = data.filesUploaded[0];

  $.ajax({
    url: options.url,
    method: 'PUT',
    data: {
      url,
      file_file_name: filename,
      file_content_type: mimetype,
      file_file_size: size,
    },
    dataType: 'script',
    headers: {
      'X-CSRF-Token': getCSRFTokenValue(),
    },
  });
};

const updateFilestackImagePreview = (data, previewOptions) => {
  const previewId = previewOptions.previewContainer;

  if (data.filesUploaded.length > 0) {
    const file = data.filesUploaded[0];
    const url = getFilestackUrlForFile(file);

    const previewDocument = document.getElementById(previewId);
    const previewInnerDoc = $(previewId).find('.image_preview');
    const previewView = previewDocument || previewInnerDoc;

    if (previewView.tagName === 'A') {
      $(previewView).attr('href', url).removeClass('hide');
    } else {
      $(previewView).attr('src', url).removeClass('hide');
    }

    $(previewView).parents('.filestack_container').find('.filestack_remove').removeClass('hide');
  }
};
window.updateFilestackImagePreview = updateFilestackImagePreview;

const initFileStack = (inputId, options, previewOptions, mimetypeId) => {
  const newOptions = options;

  let newPreviewOptions = previewOptions;

  if (typeof (fpGroupize) === 'undefined') {
    window.Sentry.captureMessage('Filestack is not initialized on this page');
    return;
  }

  $('#__filestack-picker-module').attr('id', 'old__filestack-picker-module');
  const existingCallback = newOptions.onUploadDone;

  newOptions.onClose = () => {
    $('#__filestack-picker-module').remove();
    $('#old__filestack-picker-module').attr('id', '__filestack-picker-module');
  };

  newOptions.onUploadDone = (data) => {
    window.fs_data = data;
    const inputField = document.getElementById(inputId);

    if (data.filesFailed.length > 0) {
      window.Sentry.captureMessage('Failed to upload Files');
    }

    if (inputField != null && data.filesUploaded.length > 0) {
      const file = data.filesUploaded[0];

      inputField.value = file.url;

      if (typeof mimetypeId !== 'undefined') {
        const mimetypeField = document.getElementById(mimetypeId);

        if (mimetypeField != null) {
          mimetypeField.value = file.mimetype;
        }
      }

      if (typeof (newPreviewOptions) === 'string') {
        const containerId = newPreviewOptions;
        newPreviewOptions = {
          previewCallback: updateFilestackImagePreview,
          previewContainer: containerId,
        };
      }

      newPreviewOptions.inputId = inputId;
      if (newPreviewOptions.previewCallback != null) {
        newPreviewOptions.previewCallback(data, newPreviewOptions);
      }

      if (typeof (existingCallback) !== 'undefined') {
        existingCallback(data);
      }
    }
  };

  const defaults = defaultFilestackOptions();
  const updatedOptions = { ...defaults, ...newOptions };

  // Do not allow SVG as it can include embedded JS.
  if (updatedOptions.accept.indexOf('image/*') >= 0) {
    updatedOptions.accept = filestackAllowedImageTypes;
  }

  if (updatedOptions.accept.indexOf('video/*') >= 0) {
    updatedOptions.accept = filestackAllowedVideoTypes;
  }

  window.fpGroupize.picker(updatedOptions).open();
};
window.initFileStack = initFileStack;

const initNestedFileStack = (el, selector, options, previewOptions, mimetypeSelector) => {
  const inputId = $(el).siblings(selector).attr('id');

  let mimetypeId = null;
  if (mimetypeSelector != null) {
    mimetypeId = $(el).siblings(mimetypeSelector).attr('id');
  }

  if (inputId != null) {
    if (mimetypeId != null) {
      initFileStack(inputId, options, previewOptions, mimetypeId);
    } else {
      initFileStack(inputId, options, previewOptions);
    }
  }
};
window.initNestedFileStack = initNestedFileStack;

export const initFilestackClient = () => {
  const fsScriptElem = document.querySelector('[data-fs-api-key]');

  if (fsScriptElem) {
    const decodedOptions = atob(fsScriptElem.dataset.fsInitOptions);
    const optionsObj = JSON.parse(decodedOptions);

    const clientName = fsScriptElem.dataset.fsClientName;
    if (window[clientName] != null) {
      return;
    }

    window[clientName] = window.filestack.init(fsScriptElem.dataset.fsApiKey, optionsObj);
  }
};
document.addEventListener('DOMContentLoaded', initFilestackClient);
