import Dropdown from "./dropdown_controller";

export default class extends Dropdown {
  search(e) {
    if (e.key === "Enter" || e.key === "Escape" || e.key === "Tab" || e.key === "ArrowDown" || e.key === "ArrowUp") {
      return;
    }

    if (this.optionsContainerTarget.classList.contains("tw-hidden")) {
      this.show();
    }

    this.optionTargets.forEach((option) => {
      if (option.dataset.name.toLowerCase().includes(this.displayTarget.value.toLowerCase())) {
        option.classList.remove("tw-hidden");
      } else {
        option.classList.add("tw-hidden");
      }
    });

    if (this.displayedOptions().length >= 0) {
      this.selectedIndex = 0;
      this.highlightOption();
    }
  }
}
