import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "icon"];

  toggle = (e) => {
    this.containerTarget.classList.toggle("tw-hidden");
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle("tw-rotate-180");
    }
  };
}
