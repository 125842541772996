import Dropdown from "./dropdown_controller";
import { get } from "@rails/request.js";

export default class extends Dropdown {
  static values = {
    url: String
  }

  search(e) {
    if (e.key === "Enter" || e.key === "Escape" || e.key === "Tab" || e.key === "ArrowDown" || e.key === "ArrowUp") {
      return;
    }

    const query = this.displayTarget.value.toLowerCase();
    if (query.length >= 2) {
      get(this.urlValue, { query: { q: query, container_id: this.optionsContainerTarget.id }, responseKind: "turbo-stream" }).then((response) => {
        if (response.ok) {
          this.setOptionVisibility();
        } else {
          throw new Error("Network response was not ok.");
        }
      });
    }
  }

  setOptionVisibility() {
    this.show();
  }

  // For typeahead we dont want to clear
  // the text when focused so override that behavior
  show() {
    this.open();
  }
}
