import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textContainer"];

  toggleDisplay() {
    if (this.element.dataset.maskedText) {
      this.textContainerTarget.innerText = this.element.dataset.maskedText;
      delete this.element.dataset.maskedText
    } else {
      this.element.dataset.maskedText = this.textContainerTarget.innerText;
      this.textContainerTarget.innerText = this.element.title;
    }
  }
}
